import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { TextLink } from "features/design-system/TextLink";
import { HorizontalLogoWithText } from "features/design-system/logo/HorizontalLogoWithText";
import { useSettings } from "features/settings/SettingsContext";
import { useNavigation } from "features/use-navigation/useNavigation";
import { useRouter } from "next/router";
import * as React from "react";
import { FaLightbulb } from "react-icons/fa";
import { createTexts, useTranslation } from "utils/i18n";

type SiteFooterProps = {
  slugs?: { no: string; en: string };
};
export const SiteFooter = ({ slugs }: SiteFooterProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const settings = useSettings();
  const { isLoading, footerNavigation, legalNavigation } = useNavigation();
  const background = useColorModeValue("brand.slateBlack", "gray.900");
  const color = useColorModeValue("brand.pureWhite", "gray.100");
  const { t } = useTranslation();
  const { locale, push, pathname, asPath } = useRouter();

  const handleSwitchLocale = () => {
    const newLocale = locale === "no" ? "en" : "no";
    if (slugs) {
      push("[slug]", `/${slugs[newLocale]}`, { locale: newLocale });
    } else {
      push(pathname, asPath, { locale: newLocale });
    }
  };

  return (
    <Box as="footer" background={background} color={color} paddingY={6}>
      <SimpleGrid
        columns={[1, 2]}
        gap={3}
        maxWidth="1000px"
        marginX="auto"
        paddingX={[4, 0]}
      >
        <Flex flexDirection="column">
          <Center flex="1" flexDirection="column">
            <Box>
              <HorizontalLogoWithText maxWidth="240px" />
            </Box>
          </Center>
        </Flex>
        <Stack>
          {!isLoading && (
            <>
              <Text>{settings?.description}</Text>
              <Text>
                {footerNavigation.map((item) => (
                  <React.Fragment key={item.url}>
                    <TextLink href={item.url}>{item.title}</TextLink>
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            </>
          )}
          <Text>
            <small>
              {legalNavigation.map((item, index, list) => (
                <React.Fragment key={item.url}>
                  <TextLink href={item.url}>{item.title}</TextLink>
                  {index < list.length - 1 && " - "}
                </React.Fragment>
              ))}
            </small>
          </Text>
          <Box
            as="a"
            href="https://apps.apple.com/no/app/opra/id6458589396?itsct=apps_box_badge&amp;itscg=30200"
            display="block"
            overflow="hidden"
            borderRadius="13px"
            marginY={2}
          >
            <Image
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&releaseDate=1705795200"
              alt={t(texts.appAltText)}
              borderRadius="13px"
              width="130px"
              aspectRatio={250 / 83}
            />
          </Box>
          <Flex justifyContent={["center", "flex-start"]} gap={3}>
            <Button
              size="xs"
              variant="outline"
              colorScheme="yellow"
              leftIcon={<FaLightbulb />}
              onClick={toggleColorMode}
              width="fit-content"
            >
              {colorMode === "light"
                ? t(texts.darkModeButtonText)
                : t(texts.lightModeButtonText)}
            </Button>
            <Button
              size="xs"
              variant="outline"
              colorScheme="yellow"
              leftIcon={
                locale === "no" ? (
                  <span aria-hidden>🇬🇧</span>
                ) : (
                  <span aria-hidden>🇳🇴</span>
                )
              }
              width="fit-content"
              onClick={handleSwitchLocale}
            >
              {locale === "no"
                ? t(texts.englishLangage)
                : t(texts.norwegianLanguage)}
            </Button>
          </Flex>
        </Stack>
      </SimpleGrid>
    </Box>
  );
};

const texts = createTexts({
  lightModeButtonText: {
    en: "Light mode",
    no: "Lys modus",
  },
  darkModeButtonText: {
    en: "Dark mode",
    no: "Mørk modus",
  },
  appAltText: {
    en: "Download on the App Store",
    no: "Last ned fra App Store",
  },
  englishLangage: {
    en: "English",
    no: "English",
  },
  norwegianLanguage: {
    en: "Norwegian",
    no: "Norsk",
  },
});
