import { useSettings } from "features/settings/SettingsContext";
import { useCallback, useMemo } from "react";
import { useAuth } from "utils/auth/auth-utils";

/** Get the correct navigation items based on login status */
export const useNavigation = () => {
  const settings = useSettings();
  const { isLoading, user } = useAuth();

  const filterNavigationItems = useCallback(
    (item: any) => {
      if (isLoading) {
        return false;
      }
      const isUserLoggedIn = Boolean(user);
      switch (item.availability) {
        case "allUsers":
          return true;
        case "loggedOutUsers":
          return !isUserLoggedIn;
        case "loggedInUsers":
          return isUserLoggedIn;
        case "activeUsers":
          return user?.subscriptionStatus === "ACTIVE";
      }
    },
    [isLoading, user]
  );

  return useMemo(
    () => ({
      isLoading: !settings || isLoading,
      mainNavigation:
        settings?.mainNavigation.filter(filterNavigationItems) ?? [],
      footerNavigation:
        settings?.footerNavigation.filter(filterNavigationItems) ?? [],
      legalNavigation:
        settings?.legalNavigation.filter(filterNavigationItems) ?? [],
    }),
    [settings, isLoading, filterNavigationItems]
  );
};
