import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import theme from "../../../utils/theme";

export const HorizontalLogoWithText = (props: BoxProps) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 220 80"
      width="100%"
      fill="none"
      aria-label="OPRA - Operativ rabatt"
      {...props}
    >
      <path
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill={theme.colors.brand.emergencyYellow}
      />
      <path
        d="M28.9158 66.4901C28.8276 60.066 21.0952 53.98 21.007 53.9212L18.9343 52.3335L40 9.81976L61.0511 52.3335L58.9784 53.9212C56.8174 55.5824 51.1578 61.095 51.0695 66.4754L44.7483 66.3872C44.8512 59.3898 50.1433 53.4214 53.1422 50.6136L40 24.1527L26.8577 50.6136C29.7978 53.4361 35.1489 59.4339 35.2518 66.3872L28.9158 66.4901Z"
        fill={theme.colors.brand.slateBlack}
      />
      <g fill="currentColor">
        <path d="M100 24.2718C100 13.2254 105.537 7.32355 114.007 7.32355C122.476 7.32355 128.014 13.2254 128.014 24.2718C128.014 35.3182 122.476 41.2059 114.007 41.2059C105.537 41.2059 100 35.304 100 24.2718ZM122.377 26.8441V21.6853C122.377 15.7262 119.034 12.0536 114.007 12.0536C108.979 12.0536 105.637 15.7262 105.637 21.6853V26.8441C105.637 32.8031 108.979 36.4758 114.007 36.4758C119.034 36.4758 122.377 32.8031 122.377 26.8441Z" />
        <path d="M134.571 40.6486V7.88092H148.535C154.483 7.88092 157.982 11.7821 157.982 17.7412C157.982 23.7002 154.483 27.6015 148.535 27.6015H139.825V40.6486H134.571ZM139.825 22.9572H148.209C148.767 23.0291 149.334 22.9745 149.868 22.7976C150.403 22.6206 150.891 22.3256 151.298 21.9341C151.705 21.5426 152.02 21.0644 152.221 20.5343C152.422 20.0043 152.503 19.4358 152.458 18.8702V16.6123C152.503 16.0467 152.422 15.4781 152.221 14.9481C152.02 14.4181 151.705 13.9399 151.298 13.5484C150.891 13.1569 150.403 12.8619 149.868 12.6849C149.334 12.5079 148.767 12.4534 148.209 12.5253H139.825V22.9572Z" />
        <path d="M169 40.6486H163.746V7.88092H177.753C183.574 7.88092 187.157 11.6821 187.157 17.7412C187.157 22.3855 184.962 25.6294 180.826 26.8441L187.907 40.6486H182.058L175.543 27.4157H169.071L169 40.6486ZM177.385 22.9572C177.942 23.0291 178.509 22.9745 179.043 22.7976C179.578 22.6206 180.066 22.3256 180.473 21.9341C180.88 21.5426 181.195 21.0644 181.396 20.5343C181.597 20.0043 181.678 19.4358 181.633 18.8702V16.6123C181.678 16.0467 181.597 15.4781 181.396 14.9481C181.195 14.4181 180.88 13.9399 180.473 13.5484C180.066 13.1569 179.578 12.8619 179.043 12.6849C178.509 12.5079 177.942 12.4534 177.385 12.5253H169V22.9572H177.385Z" />
        <path d="M214.519 40.6486L211.587 31.7743H199.436L196.603 40.6486H191.25L202.325 7.88092H208.925L220 40.6486H214.519ZM205.625 12.7682H205.398L200.696 27.2157H210.284L205.625 12.7682Z" />
        <path d="M220 51.2059H100V54.2059H220V51.2059Z" fill="#FFCE07" />
        <path d="M100 68.4336C100 65.681 101.325 64.2059 103.342 64.2059C105.36 64.2059 106.699 65.7266 106.699 68.4336C106.699 71.1405 105.374 72.6765 103.342 72.6765C101.311 72.6765 100 71.2013 100 68.4336ZM105.345 69.0875V67.7949C105.384 67.494 105.362 67.1878 105.279 66.8969C105.196 66.6061 105.055 66.3377 104.865 66.1097C104.675 65.8816 104.441 65.6995 104.178 65.5757C103.915 65.4519 103.63 65.3892 103.342 65.3921C103.055 65.3917 102.772 65.456 102.51 65.5807C102.249 65.7053 102.015 65.8874 101.826 66.1149C101.636 66.3423 101.495 66.6098 101.411 66.8995C101.327 67.1893 101.303 67.4945 101.34 67.7949V69.0875C101.303 69.3879 101.327 69.6932 101.411 69.9829C101.495 70.2726 101.636 70.5401 101.826 70.7675C102.015 70.9949 102.249 71.1771 102.51 71.3018C102.772 71.4264 103.055 71.4906 103.342 71.4903C103.63 71.4931 103.915 71.4305 104.178 71.3067C104.441 71.1829 104.675 71.0008 104.865 70.7728C105.055 70.5448 105.196 70.2762 105.279 69.9854C105.362 69.6946 105.384 69.3883 105.345 69.0875V69.0875Z" />
        <path d="M109.927 72.5549V64.3428H113.269C113.577 64.3237 113.884 64.3752 114.171 64.4935C114.458 64.6119 114.717 64.7944 114.93 65.0285C115.144 65.2626 115.307 65.5427 115.408 65.8496C115.509 66.1566 115.546 66.4829 115.517 66.8064C115.546 67.1299 115.509 67.4564 115.408 67.7633C115.307 68.0702 115.144 68.3504 114.93 68.5845C114.717 68.8186 114.458 69.001 114.171 69.1194C113.884 69.2377 113.577 69.2892 113.269 69.2701H111.18V72.5549H109.927ZM111.18 68.1296H113.183C113.316 68.1484 113.451 68.1354 113.578 68.0915C113.706 68.0476 113.822 67.974 113.919 67.8762C114.016 67.7783 114.091 67.6587 114.138 67.5261C114.185 67.3936 114.203 67.2516 114.191 67.1106V66.4719C114.203 66.3304 114.185 66.1879 114.138 66.0548C114.091 65.9217 114.016 65.8013 113.919 65.7023C113.823 65.6034 113.707 65.5283 113.579 65.4825C113.452 65.4368 113.316 65.4215 113.183 65.4378H111.18V68.1296Z" />
        <path d="M118.557 72.5549V64.3428H123.542V65.5138H119.825V67.7949H123.196V68.9659H119.825V71.3686H123.542V72.5549H118.557Z" />
        <path d="M128.08 72.5549H126.827V64.3428H130.169C130.477 64.3237 130.784 64.3752 131.071 64.4935C131.358 64.6119 131.617 64.7944 131.83 65.0285C132.044 65.2626 132.207 65.5427 132.308 65.8496C132.409 66.1566 132.447 66.4829 132.417 66.8064C132.464 67.3039 132.346 67.8033 132.083 68.2193C131.821 68.6353 131.429 68.9421 130.976 69.0875L132.662 72.5397H131.221L129.665 69.2244H128.123L128.08 72.5549ZM130.083 68.1296C130.216 68.1484 130.351 68.1354 130.478 68.0915C130.606 68.0476 130.722 67.974 130.819 67.8762C130.916 67.7783 130.991 67.6587 131.038 67.5261C131.085 67.3936 131.103 67.2516 131.091 67.1106V66.4719C131.103 66.3304 131.085 66.1879 131.038 66.0548C130.991 65.9217 130.916 65.8013 130.819 65.7023C130.723 65.6034 130.607 65.5283 130.479 65.4825C130.352 65.4368 130.217 65.4215 130.083 65.4378H128.08V68.0534L130.083 68.1296Z" />
        <path d="M140.629 72.5548L139.938 70.3345H137.056L136.307 72.5548H135.025L137.675 64.358H139.26L141.897 72.5548H140.629ZM138.511 65.5746L137.387 69.1939H139.678L138.511 65.5746Z" />
        <path d="M145.701 65.5138H143.381V64.3428H149.274V65.5138H146.954V72.5549H145.701V65.5138Z" />
        <path d="M151.752 72.5549V71.4751H152.818V65.3921H151.752V64.3124H155.137V65.3921H154.071V71.4751H155.137V72.5549H151.752Z" />
        <path d="M160.166 72.5548L157.63 64.358H158.927L160.166 68.4032L160.9 71.2622L161.664 68.4032L162.903 64.358H164.171L161.592 72.5548H160.166Z" />
        <path d="M172.571 72.555H171.317V64.3429H174.674C174.981 64.3235 175.288 64.3748 175.574 64.4933C175.86 64.6118 176.118 64.7946 176.331 65.0291C176.543 65.2635 176.705 65.5438 176.804 65.8508C176.904 66.1577 176.939 66.4839 176.907 66.8065C176.954 67.304 176.836 67.8034 176.574 68.2194C176.311 68.6354 175.92 68.9423 175.466 69.0877L177.138 72.5398H175.697L174.141 69.2246H172.585L172.571 72.555ZM174.573 68.1297C174.707 68.1481 174.842 68.1349 174.97 68.0911C175.099 68.0473 175.216 67.974 175.314 67.8765C175.412 67.7789 175.488 67.6596 175.537 67.5272C175.585 67.3948 175.606 67.2525 175.596 67.1107V66.472C175.608 66.3291 175.589 66.1851 175.542 66.0507C175.494 65.9164 175.418 65.7951 175.32 65.6959C175.222 65.5966 175.103 65.5218 174.974 65.4772C174.845 65.4325 174.708 65.4191 174.573 65.4379H172.571V68.0536L174.573 68.1297Z" />
        <path d="M185.119 72.5548L184.414 70.3345H181.532L180.855 72.5548H179.573L182.224 64.358H183.794L186.445 72.5548H185.119ZM182.987 65.5746L181.878 69.1939H184.169L182.987 65.5746Z" />
        <path d="M189.298 64.3426H192.727C192.999 64.3158 193.274 64.3516 193.532 64.4473C193.791 64.543 194.027 64.6964 194.224 64.8971C194.421 65.0978 194.574 65.341 194.674 65.6101C194.774 65.8793 194.817 66.168 194.801 66.4565C194.83 66.6658 194.816 66.879 194.762 67.0826C194.708 67.2862 194.614 67.4756 194.487 67.6388C194.359 67.8019 194.201 67.9352 194.022 68.0301C193.843 68.1251 193.647 68.1796 193.447 68.1901V68.1901C193.674 68.1997 193.897 68.2573 194.103 68.3595C194.309 68.4617 194.493 68.6065 194.645 68.7851C194.796 68.9638 194.913 69.1727 194.987 69.3997C195.061 69.6266 195.091 69.8669 195.075 70.1063C195.095 70.4009 195.058 70.6965 194.967 70.9758C194.876 71.2551 194.733 71.5124 194.546 71.7322C194.36 71.9521 194.133 72.1301 193.88 72.2557C193.627 72.3813 193.352 72.4519 193.072 72.4634H189.298V64.3426ZM190.551 67.8099H192.496C192.624 67.8285 192.754 67.8166 192.877 67.7751C193 67.7337 193.112 67.6638 193.206 67.5705C193.3 67.4771 193.373 67.3627 193.42 67.2358C193.466 67.1088 193.486 66.9724 193.476 66.8366V66.4717C193.486 66.3359 193.466 66.1995 193.42 66.0726C193.373 65.9456 193.3 65.8313 193.206 65.7379C193.112 65.6446 193 65.5746 192.877 65.5332C192.754 65.4917 192.624 65.4798 192.496 65.4984H190.551V67.8099ZM190.551 71.3989H192.712C192.849 71.4176 192.988 71.4038 193.119 71.3583C193.25 71.3129 193.369 71.2371 193.469 71.1364C193.568 71.0357 193.645 70.9128 193.694 70.7766C193.742 70.6405 193.762 70.4945 193.75 70.3496V69.9542C193.762 69.8092 193.742 69.6633 193.694 69.5271C193.645 69.3909 193.568 69.268 193.469 69.1673C193.369 69.0667 193.25 68.9908 193.119 68.9454C192.988 68.9 192.849 68.8861 192.712 68.9048H190.551V71.3989Z" />
        <path d="M202.999 72.5548L202.308 70.3345H199.426L198.749 72.5548H197.467L200.118 64.358H201.703L204.339 72.5548H202.999ZM200.881 65.5746L199.758 69.1939H202.048L200.881 65.5746Z" />
        <path d="M208.085 65.5138H205.765V64.3428H211.658V65.5138H209.338V72.5549H208.085V65.5138Z" />
        <path d="M216.384 65.5138H214.107V64.3428H220V65.5138H217.68V72.5549H216.427L216.384 65.5138Z" />
      </g>
    </Box>
  );
};
