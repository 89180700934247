import Head from "next/head";
import { useRouter } from "next/router";
import * as React from "react";
import type { Organization } from "schema-dts";
import { jsonLdScriptProps } from "react-schemaorg";
import { createTexts, useTranslation } from "utils/i18n";

type DefaultHeadProps = {
  /** The page title */
  title: string;
  /** The page description */
  description: string;
  /** Image shown when sharing in social media */
  someImage?: string;
  /** Search engine keywords */
  keywords?: string[];
  /** Any extra tags */
  children?: React.ReactNode;
};

/** A basic configuration for the head tags */
export const DefaultHead: React.FC<DefaultHeadProps> = ({
  title,
  description,
  someImage = "https://opra.no/default-some-image-bw.jpg",
  keywords = [],
  children,
}) => {
  const { t, language } = useTranslation();
  const { asPath } = useRouter();
  const keywordsWithDefaults = [...keywords, ...defaultKeywords[language]];
  return (
    <Head>
      <title>{`${title} | ${t(texts.meta.title)}`}</title>
      <meta
        name="description"
        content={description ?? t(texts.meta.description)}
      />
      <meta name="keywords" content={keywordsWithDefaults.join(", ")} />
      <meta
        property="og:type"
        content={asPath.includes("/nyheter") ? "article" : "website"}
      />
      <meta property="og:title" content={`${title} | ${t(texts.meta.title)}`} />
      <meta
        property="og:description"
        content={description ?? t(texts.meta.description)}
      />
      <meta property="og:image" content={someImage} />
      <meta property="og:url" content={`https://www.opra.no${asPath}`} />
      <meta property="og:site_name" content="OPRA" />
      <meta name="twitter:card" content="summary_large_image" />

      <script
        {...jsonLdScriptProps<Organization>({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "OPRA",
          url: "https://www.opra.no",
          vatID: "825901302",
          logo: "https://www.opra.no/android-chrome-512x512.png",
          foundingDate: "2020-11-03",
          foundingLocation: "Stavanger, Norway",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Lervigbrygga 128",
            addressLocality: "Stavanger",
            postalCode: "4014",
            addressCountry: t(texts.meta.country),
          },
          description: t(texts.meta.description),
          slogan: t(texts.meta.slogan),
          email: "team@opra.no",
        })}
      />

      {children}
    </Head>
  );
};

const texts = createTexts({
  meta: {
    title: {
      en: "OPRA – Discounts for operational personnel",
      no: "OPRA – Rabatter for operativt personell",
    },
    description: {
      en: "Europe's best discounts for operational personnel. Get discounts, offers and benefits from over 100 partners.",
      no: "Norges beste rabatter for operativt personell. Få rabatter, tilbud og fordeler fra over 100 partnere.",
    },
    slogan: {
      no: "Norges beste rabatter. For operative.",
      en: "Europe's best discounts. For operatives.",
    },
    country: {
      no: "Norge",
      en: "Norway",
    },
  },
});

const defaultKeywords = {
  en: ["discount code", "discount", "offer", "benefits"],
  no: ["rabattkode", "rabatt", "tilbud", "fordeler"],
} as const;
